<template>
  <div class="app-container" 
    :class="{shuping:isShuPing,
      'v-back':localShuping,
      'game-list':isGameListPage,
    }" 
    :style="{height:`${wrapperHeight}px`}"
    @click="containerClick" :data-h="wrapperHeight" ref="pageWrapperEl">
    <PopMessage :show="showPopMsg" :msg="popMsgTxt" :time="popShowTime" />
    <MsgAlert style="z-index:4" :msg="getAlertMsg" />
    <div class='loading_bj' :class="{show:isShowLoading}">
      <Loading />
    </div>
    <audio v-if="!forBiddenMusic" class="audio-control" ref="audioControl" loop 
      webkit-playsinline="true"
      x-webkit-airplay="true" 
      x5-playsinline="true"
      playsInline={true}
      x5-video-orientation="portraint">
      <source src="/source/bgMusicCaishen.mp3" type="audio/mpeg" />
    </audio>
    <RedPack v-if="userInfo.is_redpack == 1"
      :show="envelopShow" @redpackClick="redpackClickHandle"
      :cTime="countingDownTime"
      :cSecond="countingDownSecond" />
    <router-view/>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import PopMessage from './components/common/PopMessage.vue';
import Loading from './components/common/Loading.vue'
import MsgAlert from './components/common/MsgAlert.vue'
import Tool from './common/Tool.js'
import Request from './common/Request.js'
import RedPack from './components/red-pack/RedPack.vue'
import VConsole from 'vconsole';

export default{
  data(){
    return{
      showPopMsg:0,
      popMsgTxt:'',
      popShowTime:0,
      isShuPing:false,
      localShuping:false,
      userInfo:{},
      envelopShow:0,
      cdSecondTime:0,
      stopCountingDown:0,
      countingDownTime:'00:00:00',
      countingDownSecond:-1,
      frameUpdateCountingdownTimeout:0,
      prevHashid:'',
      hvCheckTimeout:0,
      checkCount:0,
      frameCount:0,
      rootEle:null,
      shuPingStyle:{},
      containerEle:null,
      hBodyBack:'',
      vBodyBack:'',
      forBiddenMusic:false,
      wrapperHeight:"",
      musicTimeout:0,
      isGameListPage:0,
    }
  },
  mounted(){
    this.windowClickInit();
    document.addEventListener('dblclick',(event) => {
      event.preventDefault();
    });
    this.setHtmlFontsize();
    this.getAgentCode();
    this.getUserInfo(() => {
      this.userInfoLoaded();
    });
    if(window.localRouteEach){
      window.localRouteEach.push(this.routeChangeHandle)
    }else{
      window.localRouteEach = [this.routeChangeHandle]
    }
    this.horizontalVerctCheck();
    this.forbiddenRefresh();
    this.containerEle = document.querySelector('.app-container');
    this.fullscreenError();
    this.backMusicInit();

    if(location.href.indexOf('sc=1') > -1){
      new VConsole();
    }
    this.getNoticeOut();
  },
  methods:{
    ...mapMutations(['setMusicSwitch','userInfoLoaded','popMsg']),
    windowClickInit(){
      window.addEventListener('click',() => {
        if(Tool.isComputer()){// 电脑
        }else{
          //此处不能封装为方法
          if(document.documentElement.requestFullscreen){
            document.documentElement.requestFullscreen();
          }
        }
      })
    },
    containerClick(){
      if(this.$route.name != 'play'){
        if(Tool.getMusicSwitch() != -1){
          if(this.musicSwitch != 1){
            this.setMusicSwitch(1);
          }
        }
      }
    },
    backMusicInit(){
      let uaStr = navigator.userAgent.toLowerCase();
      this.forBiddenMusic = uaStr.match(/heytapbrowser/i) == 'heytapbrowser';
    },
    //禁用下拉刷新
    forbiddenRefresh(){
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove",e => e.preventDefault());//禁止页面滑动
    },
    fullscreenError(){
      document.addEventListener('fullscreenerror',e => console.log(e,'screenError'))
    },
    setHtmlWidthHeight(){
      this.rootEle = this.rootEle || document.querySelector('html');
    },
    horizontalVerctCheck(){
      let frame = (flag) => {
        if(!flag){
          if(this.frameCount > 2){
            this.frameCount = 0;
            return;
          }
          this.frameCount++
          clearTimeout(this.hvCheckTimeout);
          this.hvCheckTimeout = setTimeout(() => {
            frame();
          },100);
        }
        this.setHtmlFontsize();
      }
      frame(1);

      window.addEventListener('orientationchange',() => {
        frame();
      });
      window.addEventListener('resize',() => {
        frame();
      });
    },
    routeChangeHandle(to,from){
      if(to.name != 'login'){
        this.checkUserLogin();
      }
      if(to.name == 'main'){
        this.stopCountingDown = 0;
        this.getUserInfo(() => {
          this.userInfoLoaded();
          this.genRedpackCounting();
        });
      }else{
        clearTimeout(this.cdSecondTime);
        this.countingDownSecond = 0;
        this.envelopShow = 0;
        this.stopCountingDown = 1;
      }
      
      setTimeout(() => {
        this.setHtmlFontsize();
      },600);
      
      this.hBodyBack = require('./assets/home/normal-bg.png');
      this.vBodyBack = require('./assets/home/normal-bg-v.png');
      this.isGameListPage = 0;
      if(['main','dzyy'].includes(to.name)){
        this.hBodyBack = require('./assets/home/home-bg.png');
        this.vBodyBack = require('./assets/home/home-bg-v.png');
        this.isGameListPage = 1;
      }
    },
    checkUserLogin(){
      const userInfo = Tool.getLocalUserinfo();
      if(userInfo && userInfo.hashid && 
        this.$socket && this.$socket.private){
        if(this.prevHashid == userInfo.hashid) return;
        this.prevHashid = userInfo.hashid;
        this.$socket.private('user.'+userInfo.hashid).listen('.kick', (e)=>{
          Request.userOutted(this)
        })
      }
    },
    redpackClickHandle(){
      this.frameUpdateCountingdown();
    },
    frameUpdateCountingdown(){
      clearTimeout(this.frameUpdateCountingdownTimeout);
      this.frameUpdateCountingdownTimeout = setTimeout(() => {
        this.getRedPackTimeApi();
        this.frameUpdateCountingdown();
      },1000 * 60 * 3);
    },
    getRedPackTimeApi(){
      Request.post('/activity/redpack').then(res => {
        if(res.data && res.data.data){
          const cSecond = res.data.data.count_down*1;
          this.countingDownSecond = cSecond;
          if(cSecond == 0) {
            this.showRedpackQiang()
            return;
          }
          this.runCountingDown();
        }
      });
    },
    setRedpackCountingDownTime(v){
      if(window.redpackCountingDownTick){
        window.redpackCountingDownTick(v);
      }
    },
    runCountingDown(){
      const counting = () => {
        let cdStart = this.countingDownSecond;
        if(cdStart < 0) cdStart = 0;

        const hour = Math.floor(cdStart / 60 / 60)
        const mSecond = cdStart - hour * 60 * 60;
        const minutes = Math.floor(mSecond / 60);
        const second = mSecond - minutes * 60;

        const hourStr = ('' + hour).padStart(2,0);
        const minutStr = ('' + minutes).padStart(2,0);
        const seStr = ('' + second).padStart(2,0);

        this.countingDownTime = `${hourStr}:${minutStr}:${seStr}`;
        this.setRedpackCountingDownTime(this.countingDownTime)
        
        if(this.stopCountingDown) {
          clearTimeout(this.cdSecondTime);
          return;
        }

        if(cdStart > 0){
          clearTimeout(this.cdSecondTime);
          this.cdSecondTime = setTimeout(() => {
            this.countingDownSecond--;
            counting();
          },1000);
        }else{
          this.showRedpackQiang();
        }
      };
      if(this.userInfo.is_redpack){
        counting();
      }
    },
    showRedpackQiang(){
      this.envelopShow = Math.random();
    },
    genRedpackCounting(){
      if(this.userInfo.is_redpack == 1){
        this.getRedPackTimeApi();
      }
    },
    setHtmlFontsize(){
      let noNeedShuping = Tool.noNeedShupingRotate();
      this.localShuping = innerHeight > innerWidth;
      if(innerHeight > innerWidth && !noNeedShuping){
        this.isShuPing = true;
      }else{
        this.isShuPing = false;
      }
      
      let line = this.localShuping ? innerHeight : innerWidth;
      this.wrapperHeight = this.isShuPing ? innerWidth : innerHeight;
      
      document.querySelector('html').style = 
          `font-size:${line * 100 / 770}px`;
      this.setHtmlWidthHeight();
      
      let back = this.localShuping ? this.vBodyBack:this.hBodyBack;
      document.querySelector('body').style.backgroundImage = `url(${back})`;

      if(this.isShuPing){
        document.querySelector('body').classList.add('shuping');
      }else{
        document.querySelector('body').classList.remove('shuping');
      }
    },
    showMessage(msgPaStr){
      let msgParam = msgPaStr.split('-');
      let random = msgParam[0];
      let msg = msgParam[1];
      let time = msgParam[2];
      if(!msg) return;
      this.popMsgTxt = msg;
      if(time){
        this.popShowTime = Number(time);
      }
      this.showPopMsg = Number(random);
    },
    getAgentCode(){
      const q_key = 'agent_code';
      let v = Tool.getQuery(q_key);
      if(v){
        localStorage.setItem('agent_code',v);
      }
    },
    getUserInfo(callback){
      let token = Tool.getApiToken();
      if(!token){
        if(callback) callback();
        return;
      }
      let localUserInfo = Tool.getLocalUserinfo();
      if(localUserInfo){
        this.userInfo = localUserInfo;
        if(callback) callback();
        return;
      }
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          this.userInfo = res.data.data;
          Tool.setLocalUserinfo(res.data.data);
          if(callback) callback();
        }
      }).catch(() => {
        if(callback) callback();
      });
    },
    getNoticeOut(){
      Request.get('/pub/notice_out').then(res => {
        let isQuanzhanWh = -1,infoList = [];
        if(res.data.code == 0){
          infoList = res.data.data;
          infoList.forEach((notice,i) => {
            if(notice.show_type == 5){
              isQuanzhanWh = i;
            }
          })
          /*
          idString 
          enable 
          created_at 
          idString 
          enable
          created_at
          content
          is_pc
          is_mobile
          mobile_content;
          updated_at;
          show_type;
          title;
          nodeId;
          extra;
          */
        }
        if(isQuanzhanWh != -1){
          this.$router.push({name:'maintenance'})
        }

      })
    },
  },
  watch:{
    musicSwitch(n){
      clearTimeout(this.musicTimeout);
      this.musicTimeout = setTimeout(() => {
        if(this.$refs.audioControl){
          if(n == 1){
            this.$refs.audioControl.play();
          }else{
            this.$refs.audioControl.pause();
          }
        }
      },500)
    },
    popMsgParam(n,o){
      this.showMessage(n);
    },
    getEnvelopShow(n){
      this.envelopShow = n;
    },
    isUserLoginSuccess(n){
    }
  },
  computed:{
    ...mapGetters({
      popMsgParam:'getPopMsgParam',
      musicSwitch:'getMusicSwitch',
      getAlertMsg:'getAlertMsg',
      isShowLoading:'getShowLoading',
      getEnvelopShow:'getEnvelopShow',
      isUserLoginSuccess:'getIsUserLoginSuccess',
    })
  },
  components:{
    PopMessage,
    MsgAlert,
    Loading,
    RedPack
  }
}
</script>
<style lang="stylus">
body
  background-image url(./assets/home/home-bg.png);
  &.shuping
    background-image url(./assets/home/home-bg-v.png);
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size:.17rem;
  }
}
#app
  width 100%
  height 100%
  display flex
  justify-content flex-start
  align-items flex-start
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #DDDCBD
</style>

<style lang="stylus" scoped>
.app-container
  width 100%
  height 100vh
  background-image url(./assets/home/normal-bg.png)
  background-size 100% 100%
  flex-shrink 0
  position fixed
  top 0
  left 0
  user-select none
  &.game-list
    background-image url(./assets/home/home-bg.png)
  &.shuping
    transform-origin 0 0
    transform rotateZ(90deg) translateY(-100%)
    width 8rem
    height 100vw
  .audio-control
    position absolute
    z-index -1
    opacity .0001
.spinner
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
.loading_bj
  width 100%
  height 100%
  position fixed
  top 0
  left 0
  z-index 10
  background-color rgba(0,0,0,.2)
  transition 0.2s ease-in
  transition-property background-color
  display none
  justify-content center
  align-items center
  &.show
    display flex

</style>
